import React, { useRef, useEffect, useState } from 'react';
import InsightsMap from './components/InsightsMap';
import DeviceNotes from './components/DeviceNotes/DeviceNotes';
import { Loading, StatusLight, InfoCard, useModal, OtherSpinner } from 'atoms';
import { initialDataType } from './types';
import useDeviceView from './useDeviceView';
import EventDataModal from './components/EventDataModal';
import MeasurementsTimeline from './components/MeasurementsTimeline/MeasurementsTimeline';
import moment from 'moment';

import { useDateRange } from '../../hooks/useDateRange';
import { DateRange } from './components/DateRange/DateRange';
import styles from './deviceView.module.scss';

const getTemperatureExposureText = temperatures =>
  temperatures.min && temperatures.max
    ? `${temperatures.min}${temperatures.units} / ${temperatures.max}${temperatures.units}`
    : 'No data';

const DeviceView = ({
  initialData: { device, contextualData, routes, userName },
}) => {
  const periodInputRef = useRef(null);
  const {
    markersData,
    zonesData,
    setSelectedPeriod,
    deviceFromApi,
    selectedPeriod,
    clearEventsList,
    geolocationsAreHidden,
    setGeolocationsAreHidden,
    isValidating,
  } = useDeviceView({
    device,
  });
  const [displayZones, setDisplayZones] = useState(false);
  const { isOpen, onOpen, onClose, onClickAway } = useModal();

  const { dateRange, setDateRange, resetDateRange } = useDateRange({
    currentPeriod: selectedPeriod,
    setCurrentPeriod: setSelectedPeriod,
  });

  const period = selectedPeriod;

  const utcOffset = moment().utcOffset() / 60;

  useEffect(() => {
    if (periodInputRef.current) {
      const parsedCurrentPeriod = [
        moment(period[0]).format('YYYY/MM/DD'),
        moment(period[1]).format('YYYY/MM/DD'),
      ];

      flatpickr(periodInputRef.current, {
        altInput: true,
        altFormat: 'd/m/y',
        altInputClass: `${styles.periodInput} form-control`,
        mode: 'range',
        maxDate: 'today',
        defaultDate: parsedCurrentPeriod,
        onChange: ([periodFrom, periodTo]) => {
          if (dateRange) {
            resetDateRange();
          }
          if (periodFrom && periodTo)
            setSelectedPeriod([moment(periodFrom), moment(periodTo)]);
        },
      });
    }
  }, [periodInputRef, period]);

  return (
    <div className={styles.deviceView}>
      {deviceFromApi ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3 mb-sm-0 flex-sm-wrap">
            <div
              className={`d-flex flex-column flex-sm-row mb-sm-3 ${styles.insight__titles}`}
            >
              <h1 className={`page-title mb-0 ${styles.insight__title}`}>
                Tracker ID {deviceFromApi.id || ''}:
              </h1>
              <h1
                className={`page-title mb-0 ml-sm-2 ${styles.insight__subtitle}`}
              >
                {deviceFromApi.name || ''}
                &nbsp;
              </h1>
              <a
                href={`/devices/${deviceFromApi.id}/edit`}
                className={`${styles.editLink} d-none d-sm-block btn btn-sm btn-marker-blue`}
              >
                <i className="fal fa-cogs"></i> Edit Device
              </a>
              <a className={styles.insight__backBtn} href="/">
                Back to Devices
              </a>
            </div>
            <div className="d-flex align-items-center">
              <span className="d-none d-sm-block">
                <strong>Period:</strong>
              </span>
              <div className={styles.dateWrapper}>
                <input
                  ref={periodInputRef}
                  className="form-control"
                  value={period}
                  onChange={() => {}}
                />
                <DateRange
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  className={`${styles.dateRange}`}
                />
              </div>
              {isValidating && (
                <div className="d-none d-sm-block ml-sm-3">
                  <OtherSpinner />
                </div>
              )}
            </div>
          </div>
          <div className={`${styles.dashboardRow} ${styles.dashboardColumns}`}>
            <div className={styles.dashboardCol}>
              <div className="d-flex justify-content-between my-2">
                <span className={styles.insight__insightTitle}>
                  <strong>Insights</strong>
                </span>
                <StatusLight status={deviceFromApi.status || ''} withText />
                <span className={styles.insight__batteryLevel}>
                  <strong>Battery: </strong>
                  <i className="fal fa-battery-three-quarters"></i>
                  <span>{deviceFromApi.batteryLevel}%</span>
                </span>
                <span className={styles.insight__connected}>
                  <strong>Connected: </strong>
                  {moment(deviceFromApi.lastConnected).fromNow()}
                </span>
              </div>
              <div
                className={`d-flex justify-content-between ${styles.cardsRow} ${styles.insights}`}
              >
                <InfoCard
                  title={deviceFromApi.measurements.connected.successful}
                  subtitle="Times the device Connected"
                />
                <InfoCard
                  title={getMaxGForceText(deviceFromApi.measurements.gForce)}
                  subtitle="Highest G-Force Exposure"
                />
                <InfoCard
                  title={getTemperatureExposureText(
                    deviceFromApi.measurements.minmaxTemperature,
                  )}
                  subtitle="Temperature Range (Min/Max)"
                />
              </div>
              <div className="mb-2">
                <MeasurementsTimeline
                  data={deviceFromApi.measurements}
                  isFullAdmin={deviceFromApi.isFullAdmin}
                  selectedPeriod={selectedPeriod}
                />
              </div>
              <div>
                <div className="mb-2">
                  <span>
                    <strong>Notes</strong>
                  </span>
                </div>
                <div
                  className="card p-3"
                  style={{
                    marginBottom: '25px',
                  }}
                >
                  <DeviceNotes
                    id={device.id}
                    initialNotes={device.notes}
                    userName={userName}
                  />
                </div>
                <a
                  href="#"
                  onClick={onOpen}
                  className="d-block d-sm-none text-center my-2 "
                >
                  <strong>View Raw Event Data</strong>
                </a>
                <a
                  href={`/devices/${deviceFromApi.id}/edit`}
                  className={`d-block d-sm-none text-center my-2`}
                >
                  <strong>Edit Device</strong>
                </a>
              </div>
            </div>
            <div className={`${styles.dashboardCol} ${styles.mapCol}`}>
              <div className="d-flex flex-wrap justify-content-between my-2">
                <div
                  className={`custom-control custom-switch ${styles.geozonesCheckbox}`}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="display-geozones-checkbox"
                    checked={displayZones}
                    readOnly
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="display-geozones-checkbox"
                    onClick={() => setDisplayZones(!displayZones)}
                  >
                    <strong>Display Geozones</strong>
                  </label>
                </div>
                <div
                  className={`custom-control custom-switch ${styles.geozonesCheckbox}`}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="hide-geolocations-checkbox"
                    checked={geolocationsAreHidden}
                    readOnly
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="hide-geolocations-checkbox"
                    onClick={() =>
                      setGeolocationsAreHidden(!geolocationsAreHidden)
                    }
                  >
                    <strong>Hide Geolocations</strong>
                  </label>
                </div>
                <div>
                  <strong>Display TimeZone: </strong>
                  {utcOffset < 0 ? `UTC${utcOffset}` : `UTC+${utcOffset}`}
                </div>
                <a href="#" onClick={onOpen} className="d-none d-sm-block">
                  <strong>View Raw Event Data</strong>
                </a>
              </div>
              <div className={`${styles.map}`}>
                <InsightsMap
                  {...{ markersData, zonesData, displayZones, contextualData }}
                  connectPoints
                />
              </div>
            </div>
          </div>
          <EventDataModal
            {...{ isOpen, onClose, onClickAway, clearEventsList }}
            events={deviceFromApi.events}
            deviceId={device.id}
            period={selectedPeriod}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );

  function getOpenedText(openValues) {
    const openedDays = openValues.filter(item => !!item).length;
    return openedDays;
  }

  function getMaxGForceText(gForces) {
    if (!gForces.length) {
      return '0G';
    }

    return `${Math.max(...gForces.map(v => v.value))}G`;
  }
};

export default DeviceView;

DeviceView.propTypes = {
  initialData: initialDataType,
};
