import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { uploaderType } from 'atoms/Uploader/Uploader';
import Select from 'atoms/Select/Select';
import Tooltip from 'atoms/tooltip/tooltip';

import styles from '../digitalLabelUpload.module.scss';

const FETCH_LABEL_URL = `${process.env.API_URL}/shipment_carriers/fetch_label`;

const DigitalLabelFormRetrieve = ({ deviceId, carrier, onUpload }) => {
  const [retrieveLabelError, setRetrieveLabelError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    setIsSubmitting(true);
    setRetrieveLabelError(null);

    const form = event.target;
    const formData = new FormData(form);

    const trackingCode = formData.get('trackingCode');
    const carrierId = formData.get('carrierId');

    if (!trackingCode || !carrierId) {
      setRetrieveLabelError('Please fill in all fields');
      setIsSubmitting(false);

      return;
    }

    try {
      await axios.get(
        `${FETCH_LABEL_URL}?tracking_code=${trackingCode}&device_id=${deviceId}&id=${carrierId}`,
      );
      await onUpload();
    } catch (error) {
      switch (error.response.status) {
        case 404:
          setRetrieveLabelError('Label not found');
          break;
        case 401:
          setRetrieveLabelError(
            'Wrong credentials. Please check your settings',
          );
          break;
        default:
          setRetrieveLabelError('An error occurred. Please try again');
      }
    }

    setIsSubmitting(false);
  };

  const carrierOptions = carrier.map(carrier => ({
    value: carrier.id,
    text: carrier.carrierType,
  }));

  return (
    <div className={styles.card}>
      <h3 className={styles.cardTitle}>
        Retrieve label from pre-booked shipment{' '}
        <Tooltip content="To enable this feature, please add your shipment carrier credentials to Settings / Shipment Carriers.">
          <i className="fa-light fa-circle-info" />
        </Tooltip>
      </h3>
      {carrierOptions.length > 0 ? (
        <form className={styles.cardForm} onSubmit={handleSubmit}>
          {retrieveLabelError && (
            <p className={styles.errorMessage}>{retrieveLabelError}</p>
          )}
          <Select
            name="carrierId"
            options={carrierOptions}
            className={`${styles.cardInput} ${styles.cardSelect}`}
          />
          <input
            name="trackingCode"
            className={styles.cardInput}
            placeholder="Tracking Code"
          />
          <button
            disabled={isSubmitting}
            className={styles.cardButton}
            type="submit"
          >
            Retrieve label
            {isSubmitting && <i className="fa fa-spinner fa-spin ml-1" />}
          </button>
        </form>
      ) : null}
    </div>
  );
};

DigitalLabelFormRetrieve.propTypes = {
  deviceId: PropTypes.string,
  carrier: PropTypes.array,
  uploader: uploaderType,
  onUpload: PropTypes.func,
};

export default DigitalLabelFormRetrieve;
