import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import styles from '../digitalLabelUpload.module.scss';

const HoldingLabelButton = ({ holdingLabelId, onUpload }) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async () => {
    setIsPending(true);
    setError(null);

    try {
      await axios.post(`/devices/${holdingLabelId}/upsert_holding_label`);
      await onUpload();
    } catch (error) {
      setIsPending(false);
      setError('Something went wrong. Please try again.');

      return;
    }

    setIsPending(false);
  };

  return (
    <button className={styles.card} onClick={handleClick}>
      <div className={styles.cardContainer}>
        <div>
          <h3 className={styles.cardTitle}>Display Holding Label</h3>
          <p className={styles.cardDescription}>
            Upload a default label under settings
          </p>
        </div>
        {isPending ? (
          <i className="fa-light fa-spinner fa-spin" style={{ fontSize: 24 }} />
        ) : (
          <i className="fa-light fa-file" style={{ fontSize: 24 }} />
        )}
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    </button>
  );
};

HoldingLabelButton.propTypes = {
  holdingLabelId: PropTypes.string,
  onUpload: PropTypes.func,
};

export default HoldingLabelButton;
