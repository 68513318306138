import React, { useMemo, useEffect } from 'react';
import PropTypes, { any } from 'prop-types';
import { ExpandableTable, StatusLight, Empty, TableData } from 'atoms';
import DashboardTabs from './tabs/DashboardTabs';
import moment from 'moment';
import { deviceType } from 'components/TrackingDashboard/types';

const DevicesTable = ({
  boxOpenInfo,
  data,
  getRowsData,
  setColumnsNumber,
  showMap,
  singleExpand,
  mapLayer,
  searchFilter,
  isFullAdmin,
}) => {
  const NO_DATA_TEXT = '';
  useEffect(() => {
    moment.updateLocale('en', {
      relativeTime: {
        s: 'seconds',
      },
    });
  }, []);

  const deviceRowPropTypes = {
    row: PropTypes.shape({
      values: deviceType,
    }),
  };
  const StatusContentCell = ({ row: { values } }) => (
    <StatusLight status={values.status} />
  );
  StatusContentCell.propTypes = deviceRowPropTypes;

  const IdContentCell = ({ row: { values } }) => <TableData data={values.id} />;
  IdContentCell.propTypes = deviceRowPropTypes;

  const NameContentCell = ({ row: { values } }) => (
    <TableData label={`ID: ${values.id}`} data={values.name} />
  );
  NameContentCell.propTypes = deviceRowPropTypes;

  const LastConnectedContentCell = ({ row }) => {
    const lastConnected = row.values['lastConnected'];
    if (!lastConnected) {
      return NO_DATA_TEXT;
    }
    return (
      <TableData label={'Connected'} data={moment(lastConnected).fromNow()} />
    );
  };
  LastConnectedContentCell.propTypes = deviceRowPropTypes;

  const boxOpenedContentCell = ({ row }) => {
    const boxOpened = row.values['boxOpened'];
    if (!boxOpened) {
      return NO_DATA_TEXT;
    }
    return <TableData data={moment(boxOpened).fromNow()} />;
  };
  boxOpenedContentCell.propTypes = deviceRowPropTypes;

  const BatteryLevelCell = ({ row }) => {
    const batteryLevel = row.values['batteryLevel'];
    if (typeof batteryLevel === 'undefined' || batteryLevel === null) {
      return NO_DATA_TEXT;
    }
    return (
      <TableData
        label={<i className="fal fa-battery-three-quarters" />}
        data={`${batteryLevel}%`}
      />
    );
  };
  BatteryLevelCell.propTypes = deviceRowPropTypes;

  const columns = useMemo(
    () =>
      [
        {
          Header: 'ID:',
          accessor: 'id',
          Cell: IdContentCell,
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: StatusContentCell,
        },
        {
          Header: 'Name',
          accessor: 'name',
          Cell: NameContentCell,
        },
        {
          Header: 'Connected',
          accessor: 'lastConnected',
          Cell: LastConnectedContentCell,
        },
        boxOpenInfo && !isFullAdmin
          ? {
              Header: 'Box Opened:',
              accessor: 'boxOpened',
              Cell: boxOpenedContentCell,
            }
          : null,
        {
          Header: <i className="fal fa-battery-three-quarters"></i>,
          accessor: 'batteryLevel',
          Cell: BatteryLevelCell,
        },
        {
          Header: () => null,
          id: 'expander',
          Cell: props => (
            <ExpandableTable.ExpandRowButton {...props} mapLayer={mapLayer} />
          ),
          disableSortBy: true,
        },
      ].filter(x => x !== null),
    [mapLayer],
  );

  useEffect(() => {
    setColumnsNumber(columns.length);
  }, [columns]);

  return (
    <ExpandableTable
      {...{
        columns,
        data,
        renderRowSubComponent: DashboardTabs,
        getRowsData,
        showMap,
        singleExpand,
        emptyComponent: searchFilter ? (
          <Empty>No search results found</Empty>
        ) : (
          <Empty>
            No devices activated yet, click <b>Activate Device</b> on the top
            right
          </Empty>
        ),
        paginationClassName: 'small',
        sortBy: [{ id: 'id', desc: false }],
      }}
    />
  );
};

DevicesTable.propTypes = {
  boxOpenInfo: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  getRowsData: PropTypes.func,
  setColumnsNumber: PropTypes.func,
  showMap: PropTypes.func,
  singleExpand: PropTypes.bool,
  mapLayer: PropTypes.bool,
  searchFilter: PropTypes.string,
  isFullAdmin: PropTypes.bool,
};

export default DevicesTable;
