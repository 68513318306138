import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { uploaderType } from 'atoms/Uploader/Uploader';

import styles from './digitalLabelUpload.module.scss';
import DigitalLabelUploader from './digitalLabelUploader/DigitalLabelUploader';
import DigitalLabelFormRetrieve from './digitalLabelFormRetrieve/digitalLabelFormRetrieve';
import HoldingLabelButton from './holdingLabelButton/holdingLabelButton';

const DigitalLabelUpload = ({
  deviceId,
  carrier,
  uploader,
  onUpload,
  holdingLabel,
  holdingLabelId,
}) => (
  <div className={styles.container}>
    <DigitalLabelUploader uploader={uploader} onUpload={onUpload} />
    {holdingLabel && (
      <HoldingLabelButton onUpload={onUpload} holdingLabelId={holdingLabelId} />
    )}
    <DigitalLabelFormRetrieve
      deviceId={deviceId}
      carrier={carrier}
      onUpload={onUpload}
    />
  </div>
);

DigitalLabelUpload.propTypes = {
  deviceId: PropTypes.string,
  carrier: PropTypes.array,
  uploader: uploaderType,
  onUpload: PropTypes.func,
  holdingLabel: PropTypes.bool,
  holdingLabelId: PropTypes.string,
};

export default DigitalLabelUpload;
