import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useUpload from 'atoms/Uploader/useUpload';
import { uploaderType } from 'atoms/Uploader/Uploader';
import { DEFAULT_RESTRICTIONS } from 'atoms/Uploader/uploader.constants';
import Tooltip from 'atoms/tooltip/tooltip';

import styles from '../digitalLabelUpload.module.scss';

const DigitalLabelUploader = ({ uploader, onUpload }) => {
  const [uploadLabelError, setUploadLabelError] = useState(null);

  const handleUploadError = error => {
    setUploadLabelError(error.message);
  };

  const { progress, handleInputChange } = useUpload({
    uploader,
    restrictions: DEFAULT_RESTRICTIONS,
    onError: handleUploadError,
    onFinish: onUpload,
  });

  return (
    <label
      htmlFor={progress > 0 ? '' : uploader.inputId}
      className={styles.card}
      style={{
        cursor: progress > 0 ? 'default' : 'pointer',
      }}
    >
      <div className={styles.cardContainer}>
        <div>
          <h3 className={styles.cardTitle}>
            Upload a shipping label{' '}
            <Tooltip content="Any .Pdf, jpg, jpeg, png, gif, bmp. Maximum file size 2mb. 4 x 6 Aspect Ratio.">
              <i className="fa-light fa-circle-info" />
            </Tooltip>
          </h3>
          <p className={styles.cardDescription}>From PDF or Image File</p>
        </div>
        {progress == 0 ? (
          <i className="fa-light fa-upload" style={{ fontSize: 24 }} />
        ) : (
          <i className="fa-light fa-spinner fa-spin" style={{ fontSize: 24 }} />
        )}
        {uploadLabelError && (
          <p className={styles.errorMessage}>{uploadLabelError}</p>
        )}
      </div>
      <input
        id={uploader.inputId}
        type="file"
        hidden
        name={`${uploader.objectName}[${uploader.attributeName}]`}
        onChange={handleInputChange}
      />
    </label>
  );
};

DigitalLabelUploader.propTypes = {
  uploader: uploaderType,
  onUpload: PropTypes.func,
};

export default DigitalLabelUploader;
