import React from 'react';
import PropTypes from 'prop-types';

import styles from './digitalLabelView.module.scss';

const DigitalLabelView = ({
  currentLabelFileName,
  currentLabelUrl,
  pendingLabelFileName,
  pendingLabelUrl,
}) => {
  const openInNewTab = url => {
    window.open(url, '_blank');
  };

  return (
    <div className={styles.labelsContainer}>
      {currentLabelUrl && (
        <div className={styles.labelWrapper}>
          <div
            className={`${styles.imageWrapper} border`}
            onClick={() => openInNewTab(currentLabelUrl)}
          >
            <img
              className={styles.image}
              src={currentLabelUrl}
              alt={currentLabelFileName}
            />
          </div>
          <div className={styles.labelNameWrapper}>
            Current label
            <p className={styles.labelName}>{currentLabelFileName}</p>
          </div>
        </div>
      )}
      {pendingLabelUrl && (
        <div className={styles.labelWrapper}>
          <div
            className={styles.imageWrapper}
            onClick={() => openInNewTab(pendingLabelUrl)}
          >
            <img
              className={styles.image}
              src={pendingLabelUrl}
              alt={pendingLabelFileName}
            />
            <div className={styles.imageOverflow} />
          </div>
          <div className={styles.labelNameWrapper}>
            <span className="d-inline-flex align-items-center">
              <i className="fal fa-clock mr-1" style={{ fontSize: 14 }} />
              Pending label
            </span>
            <p className={styles.labelName}>{pendingLabelFileName}</p>
          </div>
        </div>
      )}
    </div>
  );
};

DigitalLabelView.propTypes = {
  currentLabelFileName: PropTypes.string,
  currentLabelUrl: PropTypes.string,
  pendingLabelFileName: PropTypes.string,
  pendingLabelUrl: PropTypes.string,
};

export default DigitalLabelView;
